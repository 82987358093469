input,
textarea,
.form-control,
.form-box input,
.form-box textarea {
  background-color: transparent;
  border: none;
  flex: 1 1 auto;
  font-size: 14px;
  line-height: 1.7;


  &, &::placeholder {
    // color: $body-color;
    color: rgb(205, 205, 205);
    font-weight: 500;
    font-size: 14px;
  }

  @include media_768 {
    flex: unset;
    display: block;
    width: 100%;
  }

  &:focus {
    background: inherit;
    border: none;
    box-shadow: none;
  }
}

textarea,
// .form-control,
.form-box textarea {
  min-height: 120px;

}



// custom radio
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: black;
  margin: 0;
  font: inherit;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid white;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;

}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: var(--line-color);
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid white;
  outline-offset: max(2px, 0.15em);
}


// custom checkbox
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  // padding:1.15em;
  max-width: 2.15em!important;
  height: 2.15em;
  transform: scale(0.5)!important;
  border:3px solid $line-color;
  // margin-top: 10px;
  margin-right: 10px;
  border-radius: 0;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: center center;
  // transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em 0 0 $line-color;
  background-color: $line-color!important;
}

input[type="checkbox"]:checked::before {
  transform: scale(2);
}

input[type="checkbox"]:focus {
  outline: max(3px, 0.15em) solid $line-color;
}
.checkbox-custom{
  cursor: pointer;
}

// End custom checkbox


input[type="search"] {
  &::-webkit-search-decoration {
    display: none;
  }
}


.form-box {
  position: relative;

  .form-group {
    display: flex;
    border-bottom: 1px solid $border-color;
    padding-bottom: 15px;
    margin-bottom: 30px;
    @include media_768 {
      flex-direction: column;
      label {
        flex: unset;
        display: block;
        margin-bottom: 10px;
      }
    }

    label {
      flex: 0 0 auto;
      padding-right: 30px;
      color: $heading-color;
      font-size: 15px;
      letter-spacing: 1px;
    }
  }
}